@import './_mixins.scss';
@import './_variables.scss';

@include block('project-tile') {
  margin-bottom: 24vh;

  @include sm-desktop {
    margin-bottom: 33vh;

    &:nth-of-type(2n) {
      .project-tile__container {
        flex-flow: row;
      }

      .project-tile__content {
        padding-left: 56px;
        margin: 0 0 0 32px;
      }
    }
  }

  @include element('container') {
    @include sm-desktop {
      display: flex;
      flex-flow: row-reverse;

      align-items: flex-start;
      align-items: center;

      padding: 24px;
    }
  }

  @include element('content') {
    padding: 6vh 16px 24px 16px;

    @include sm-desktop {
      flex: 1 0 50%;
      padding-top: 0;
      padding-right: 56px;

      display: flex;
      flex-flow: column;
      justify-content: flex-end;

      margin: 0 32px 0 0;
    }
  }

  @include element('image-wrapper') {
    @include sm-desktop {
      display: flex;
      flex-flow: column;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  @include element('image') {
    box-shadow: $shadow;

    @include modifier('stitched-ar') {
      box-shadow: none;
      filter: drop-shadow($drop-shadow);
    }
  }

  @include element('title') {
    @include subheading-font;
    margin-bottom: 16px;
  }

  @include element('technologies-subtitle') {
    @include body-font;
    color: $light-grey;
    margin: 20px 0 0 0;

    @include sm-desktop {
      margin: 0 0 6px 0;
    }
  }

  @include element('technologies') {
    display: flex;
    flex-flow: wrap;
    margin: 8px 0;

    @include sm-desktop {
      margin-bottom: 0;
    }
  }

  @include element('technology') {
    @include body-font;
    text-align: left;

    padding-bottom: 4px;

    &:after {
      content: '/';
      display: inline;
      padding: 0 16px;
    }

    &:last-child {
      border: none;

      &::after {
        display: none;
      }
    }
  }

  @include element('excerpt') {
    @include body-font;

    margin: 32px 0;
  }

  @include element('see-more') {
    @include link;

    @include sm-desktop {
      display: none;
    }

    @include modifier('desktop') {
      display: none;

      @include sm-desktop {
        display: block;
        margin-top: 32px;
        transform: translateX(0);
      }
    }
  }
}
