@import './_mixins.scss';
@import './_variables.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: $page-background-colour;
  font-family: 'Inconsolata', monospace;
  text-rendering: optimizeLegibility;
}

button {
  border: none;
  font-family: 'Inconsolata', monospace;
  text-rendering: optimizeLegibility;
  background-color: transparent;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

#root::before {
  content: '';
  height: $navigation-height-mobile;
  display: block;

  @include tablet {
    height: $navigation-height-tablet;
  }
}
