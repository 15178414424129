@import './_mixins.scss';
@import './_variables.scss';

@include block('footer') {
  background-color: $footer-background-colour;

  @include element('content') {
    max-width: calc(1150px + (180px * 2) + (32px * 2));

    display: flex;
    flex-flow: column;

    padding: 24px $side-padding-mobile 80px
      $side-padding-mobile;
    margin: 0 auto;

    @include sm-desktop {
      flex-flow: row;
      justify-content: space-between;

      padding: 24px 40px 32px 40px;
    }
  }

  @include element('text') {
    max-width: $max-width-content-desktop;
    @include body-font;
    margin-bottom: 56px;

    @include sm-desktop {
      margin-bottom: 0;
    }
  }

  @include element('link') {
    display: inline;
    margin: 0 4px;
  }

  @include element('back-to-top') {
    @include link;
    height: fit-content;
    font-size: 18px;

    @include tablet {
      font-size: 18px;
    }

    @include sm-desktop {
      font-size: 18px;
    }

    @include med-desktop {
      font-size: 18px;
    }
  }
}
