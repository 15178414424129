// Font sizes
$homepage-body-mobile: 24px;
$homepage-body-tablet: 24px;
$homepage-body-sm-desktop: 24px;
$homepage-body-med-desktop: 24px;

$heading-1-mobile: 32px;
$heading-1-tablet: 32px;
$heading-1-sm-desktop: 32px;
$heading-1-med-desktop: 48px;

$subheading-mobile: 30px;
$subheading-tablet: 30px;
$subheading-sm-desktop: 20px;
$subheading-med-desktop: 30px;

$title-mobile: 24px;
$title-tablet: 24px;
$title-sm-desktop: 24px;
$title-med-desktop: 24px;

$body-mobile: 20px;
$body-tablet: 20px;
$body-sm-desktop: 18px;
$body-med-desktop: 20px;

$max-width-desktop: 1150px;
$max-width-content-desktop: 610px;
$side-padding-mobile: 16px;
$side-padding-tablet: 40px;

$navigation-height-mobile: 83px;
$navigation-height-tablet: 107px;

$spacer-small: 5vh;
$spacer-large: 10vh;

$light-black: #404040;
$light-grey: $light-black;
$white: white;
$black: black;
$link-underline: black;
$hover-background: #f7f7f7;
$hover-outline: #cccccc;
$shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
$drop-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
$focus-outline: #d1e4dd;

$footer-background-colour: #d1d7e5;
// $footer-background-colour: #d1e3dc;

$page-background-colour: #f7f7f7;
// $page-background-colour: #f9f8ef;

// $page-background-colour: #dbdee4;
// $page-background-colour: #d4d8f2;
// $page-background-colour: #f0ebff;
// $page-background-colour: #dbdee4;
// $page-background-colour: #faedca;
// $page-background-colour: #d1e3dc;
// $page-background-colour: #d1d7e5;

// $hover-purple: #4e57ef;
// $hover-green: #7caa5e;
// $hover-purple: #3f51b5;
// $hover-purple: #a2d7ff;
// $hover-green: #66e2d5;

// hover transition
// transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1) 0.1s;
// $light-grey: #9b9999;

// Desktop image size
// 1207 x 741
