@import './_mixins.scss';
@import './_variables.scss';

@include block('about') {
  min-height: 94.5vh;

  @include element('content') {
    padding: $spacer-large $side-padding-mobile 20vh
      $side-padding-mobile;

    @include tablet {
      flex-flow: row;

      max-width: $max-width-content-desktop;
      padding-top: 20vh;
      padding-left: 40px;
      padding-bottom: $spacer-large;

      margin: 0 auto;
    }

    @include lg-desktop {
      padding-top: 20vh;
    }
  }

  @include element('header') {
    @include subheading-font;
    margin: 32px 0 24px;

    @include tablet {
      margin-top: 0;
    }
  }

  @include element('description') {
    @include body-font;
    margin-bottom: $spacer-small;
  }

  @include element('personality-list-container') {
    margin-bottom: $spacer-large;

    @include tablet {
      padding-right: 64px;
    }
  }

  @include element('personality-list') {
    @include body-font;
    list-style: '- ' inside none;
  }

  @include element('links') {
    display: flex;
    flex-flow: column;

    margin-bottom: $spacer-large;

    @include tablet {
      flex-flow: row;
      justify-content: center;
    }
  }

  @include element('list-header') {
    @include body-font;
    margin-bottom: 24px;
  }

  @include element('link') {
    width: 100%;

    &:first-child {
      margin-bottom: 16px;
    }

    @include tablet {
      width: fit-content;

      &:first-child {
        margin: 0 16px 0;
      }
    }
  }

  @include element('resource-list') {
    display: flex;
    flex-flow: wrap;
  }

  @include element('resource-list-item') {
    @include body-font;
    display: flex;
    align-items: center;

    &:after {
      content: '/';
      display: inline-flex;
      margin: 0 16px 0 4px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}
