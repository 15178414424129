@import './_mixins.scss';
@import './_variables.scss';

@include block('home') {
  height: 100vh;
  padding: 0 $side-padding-mobile;

  @include tablet {
    padding: 0 $side-padding-tablet;
  }

  @include element('content') {
    max-width: 380px;
    text-align: left;

    padding: 15vh 24px 0;

    @include tablet {
      max-width: $max-width-content-desktop;
      padding-top: 20vh;
      padding-left: 40px;
    }

    @include sm-desktop {
      max-width: $max-width-content-desktop;

      padding-top: 15vh;
      margin: 0 auto;
    }

    @include lg-desktop {
      padding-top: 20vh;
    }
  }

  @include element('text') {
    @include homepage-body-font;
    margin-bottom: 40px;

    @include sm-desktop {
      margin-bottom: 48px;
    }
  }

  @include element('link') {
    width: fit-content;
    margin: $spacer-large auto 0 auto;
  }
}
